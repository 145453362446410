/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { printLog } from '../lib';
import { composizioniStruct } from '../strutture/composizioni.strutture';
import { righeComposizioni } from '../strutture/righeComposizioni.strutture';
import { CommonFunctionService } from './common-function.service';
import { GlobalVariableService } from './global-variable.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ComposizioniService {
  catalogoAttivo = null;

  private destroy = new Subject();

  constructor(
    private common: CommonFunctionService,
    private globalVar: GlobalVariableService,
    private alertCtrl: AlertController,
    public translate: TranslateService
  ) {}

  //* testate
  async reloadT() {
    const testate = await this.common.getComposizioniT();
    return testate;
  }

  async nuovaT(TC_payload: composizioniStruct) {
    const response = await this.common.doNuovaComposizioneT(TC_payload);
    // printLog('response nuova testata', response);
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');
    if (risp === '"200"') {
      await this.reloadT();
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: this.translate.instant('A018'),
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: risp,
        buttons: [
          {
            text: this.translate.instant('001'),
            role: 'confirm',
          },
        ],
      });
      await alert.present();
    }
  }

  async getT(num_ordine: string) {
    const testata = await this.common.getComposizioneT(num_ordine);
    return testata;
  }

  async deleteT(codice_compo: string){
    const response = await this.common.delComposizioneT(codice_compo);
    console.log('repsonse elimina testata composizioni', response);
  }

  //* righe
  async getR(codice_compo: string) {
    const righeDaServer = await this.common.getComposizioniR(codice_compo);
    return righeDaServer;
  }

  async setRJSON(codice_compo: string, R_payload: righeComposizioni[]) {
    const response = await this.common.setComposizioniR(
      codice_compo,
      R_payload
    );
    // printLog('response nuova riga composizioni', response);
    const risp = JSON.stringify(response).split(':').pop().replace('}', '');

    if (risp === '"200"') {
      await this.getR(codice_compo);
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: 'Composizone salvata',
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        header: risp,
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });
      await alert.present();
    }
  }
}
